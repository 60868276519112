import React from "react"
import { Router } from "@reach/router"
import Dashboard from '../../app/dashboard/index'
import PrivateRoute from "../../components/hoc/PrivateRoute"
import Pricing from "../../app/Settings"

const App = (props) => {
  const lang = props.pageContext.intl.language

  return (
    <Router basepath={`/${lang}/`}>
      
{/*       
      <Dashboard path="app/*">
      <Pricing path="pricing" />
      </Dashboard>
       */}

    <Pricing path="/app/settings" />
      <PrivateRoute path="/app/settings/" component={Pricing}/>
      <PrivateRoute path="/app/" component={Dashboard} default />
        

      
    </Router>
)}
export default App